import Model from "./Model";
import Sections from "./Sections";

export default class Lesson extends Model {

  id = null;
  type = null;
  name = null;
  title = null;
  description = null;
  image = null;
  ordinal = null;
  position = null;
  toolkit_id = null;
  chapter_id = null;
  modul_id = null;
  created_at = null;
  updated_at = null;
  quote = null;
  author = null;
  quotation = null;
  instructions = null;
  ratings = [];
  sections_count = 0;
  sections = new Sections();

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
    if (data && data["sections"]) {
      this.sections = new Sections(data["sections"]);
    }
    else {
      this.sections = new Sections();
    }
  }

  async store() {
    return this._store(`lessons/${this.toolkit_id}/${this.id}`);
  }

  static async restore(toolkit, id, sections = false) {
    let path = `lessons/${toolkit}/${id}`;
    let lesson = Model._restore(Lesson, path);
    if (lesson && sections) {
      lesson.sections = Sections.restore(toolkit, id);
    }
    return lesson;
  }

  static async load(toolkit, id, sections = false) {
    let path = `toolkits/${toolkit}/lessons/${id}.json?sections=${sections}`;
    return Model._load(Lesson, path);
  }

}