export default {
  computed: {
    user() {
      return this.$store.getters.getUser();
    },
    toolkit() {
      return this.$store.getters.getToolkit();
    },
    toolkits() {
      return this.$store.getters.getToolkits();
    },
    completions() {
      return this.$store.getters.getToolkitCompletions(this.user, this.selectedToolkit);
    },
    currentToolkits() {
      return this.$store.getters.getCurrentToolkits(this.user);
    },
    selectedToolkit() {
      return this.currentToolkit?.id || this.currentToolkit;
    }
  },
  methods: {
    async loadToolkit(refresh = false) {
      if (this.hasAbility("read", "Toolkit")) {
        await this.storeDispatch("loadToolkit", {
          refresh: refresh,
          toolkit: this.selectedToolkit
        });
      }
    },
    async loadChapters(refresh = false) {
      if (this.hasAbility("read", "Chapter")) {
        await this.storeDispatch("loadChapters", {
          refresh: refresh,
          toolkit: this.selectedToolkit
        });
      }
    },
    async loadModuls(refresh = false) {
      if (this.hasAbility("read", "Modul") && this.toolkit.moduls_count > 0) {
        await this.storeDispatch("loadModuls", {
          refresh: refresh,
          toolkit: this.selectedToolkit
        });
      }
    },
    async loadLessons(refresh = false) {
      if (this.hasAbility("read", "Lesson")) {
        await this.storeDispatch("loadLessons", {
          refresh: refresh,
          toolkit: this.selectedToolkit
        });
      }
    },
    async loadCompletions(params = {}) {
      return await this.storeDispatch("loadCompletions", {
        user: this.user.id,
        toolkit: this.selectedToolkit,
        limit: 250,
        ...params
      });
    },
    toolkitChapters(toolkit) {
      return this.$store.getters.getToolkitChapters(toolkit);
    },
    chapterModuls(chapter) {
      return this.$store.getters.getChapterModuls(chapter);
    },
    chapterLessons(chapter) {
      return this.$store.getters.getChapterLessons(chapter);
    },
    modulLessons(modul) {
      return this.$store.getters.getModulLessons(modul);
    },
    lessonCompletion(lesson) {
      return this.completions ? this.completions.find(completion => completion.lesson_id == lesson.id) : null;
    },
    isLocked(lesson) {
      let user = this.$store.getters.getUser();
      return user?.district_demo_content_only && !lesson?.demo_content;
    }
  }
};
