import Model from "./Model";

export default class Section extends Model {

  id = null;
  type = null;
  name = null;
  title = null;
  description = null;
  ordinal = null;
  file = null;
  image = null;
  audio = null;
  video;
  road_trip_nation_id = null;
  url = null;
  step = null;
  minimum = null;
  maximum = null;
  options = null;
  instructions = null;
  required = false;
  toolkit_id = null;
  chapter_id = null;
  lesson_id = null;
  created_at = null;
  updated_at = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
    if (data["options"] && data["options"].toString() == "en,") {
      this.options = [];
    }
    if (data["instructions"] && data["instructions"].toString() == "en,") {
      this.instructions = [];
    }
  }

  async store() {
    return this._store(`sections/${this.toolkit_id}/${this.lesson_id}/${this.id}`);
  }

  static async restore(toolkit, lesson, id) {
    return Model._restore(Section, `sections/${toolkit}/${lesson}/${id}`);
  }

  static async load(toolkit, lesson, id) {
    return Model._load(Section, `toolkits/${toolkit}/lessons/${lesson}/sections/${id}.json`);
  }

}