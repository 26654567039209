import Server from "../services/Server";
import Model from "./Model";

export default class Completion extends Model {

  id = null;
  type = null;

  user_id = null;
  user_name = null;

  toolkit_id = null;
  toolkit_name = null;

  chapter_id = null;
  chapter_name = null;

  lesson_id = null;
  lesson_name = null;

  term_id = null;
  term_name = null;

  responses_count = 0;

  reviewed_at = null;
  reviewed_by = null;

  created_at = null;
  updated_at = null;
  completed_at = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async store() {
    return this._store(`completions/${this.user_id}/${this.toolkit_id}/${this.chapter_id}/${this.lesson_id}`);
  }

  async storeByClassroom(classroom) {
    return this._store(`completions/${classroom}/${this.user_id}/${this.toolkit_id}/${this.chapter_id}/${this.lesson_id}`);
  }

  static async restore(user_id, toolkit_id, chapter_id, lesson_id) {
    return Model._restore(Completion, `completions/${user_id}/${toolkit_id}/${chapter_id}/${lesson_id}`);
  }

  static async load(id) {
    return Model._load(Completion, `completions/${id}.json`);
  }

  async save() {
    return this._save(Completion, "completions.json", {
      lesson_id: this.lesson_id,
      term_id: this.term_id
    });
  }

  async markReviewed() {
    const data = await Server.put(`/lessons/${this.lesson_id}/completions/${this.id}.json?reviewed=true`);
    if (data) {
      return true;
    }
    return null;
  }

  async delete() {
    await this._delete(`completions/${this.id}.json`);
    // await this._remove(`completions/${this.id}`);
    return null;
  }
}