import Models from "./Models";
import Modul from "./Modul";

export default class Moduls extends Models {

  constructor(models = []) {
    super(Modul, models);
  }

  static async clear() {
    return Models._clear("moduls");
  }

  static async count(toolkit, chapter, lessons = false, sections = false, search = "", offset = 0, limit = 10) {
    // TODO utilize lessons and sections flags
    let path = chapter ? `moduls/${toolkit}/${chapter}` : `moduls/${toolkit}/`;
    return Models._count(path, search, offset, limit, "ordinal", "name,title,description");
  }

  static async restore(toolkit, chapter, lessons = false, sections = false, search = "", offset = 0, limit = 10) {
    // TODO utilize lessons and sections flags
    let path = chapter ? `moduls/${toolkit}/${chapter}` : `moduls/${toolkit}/`;
    return Models._restore(Moduls, Modul, path, search, offset, limit, "ordinal", "name,title,description");
  }

  static async load(toolkit, chapter = null, lessons = false, sections = false, search = "", offset = 0, limit = 10, deleted = false) {
    let path = chapter ? `toolkits/${toolkit}/chapters/${chapter}/moduls.json?lessons=${lessons}&sections=${sections}` : `toolkits/${toolkit}/moduls.json?lessons=${lessons}&sections=${sections}`;
    return Models._load(Moduls, Modul, path, search, offset, limit, deleted);
  }

}