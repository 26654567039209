import Models from "./Models";
import Chapter from "./Chapter";

export default class Chapters extends Models {

  constructor(models = []) {
    super(Chapter, models);
  }

  static async clear() {
    return Models._clear("chapters");
  }

  static async count(toolkit, moduls = false, lessons = false, sections = false, search = "", offset = 0, limit = 10) {
    // TODO utilize moduls, lessons and sections flags
    return Models._count(`chapters/${toolkit}/`, search, offset, limit, "ordinal", "name,title,description");
  }

  static async restore(toolkit, moduls = false, lessons = false, sections = false, search = "", offset = 0, limit = 10) {
    // TODO utilize moduls, lessons and sections flags
    return Models._restore(Chapters, Chapter, `chapters/${toolkit}/`, search, offset, limit, "ordinal", "name,title,description");
  }

  static async load(toolkit, moduls = false, lessons = false, sections = false, search = "", offset = 0, limit = 10, deleted = false) {
    let path = `toolkits/${toolkit}/chapters.json?moduls=${moduls}&lessons=${lessons}&sections=${sections}`;
    return Models._load(Chapters, Chapter, path, search, offset, limit, deleted);
  }

}