import Model from "./Model";

export default class Total extends Model {

  id = null;

  school_id = null;
  school_name = null;

  classroom_id = null;
  classroom_name = null;

  question_id = null;
  question_name = null;

  support_id = null;
  support_name = null;

  started_on = null;
  finished_on = null;

  mean = 0;
  mode = 0;
  median = 0;

  low = 0;
  high = 0;
  range = 0;

  tally = {};

  created_at = null;
  updated_at = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async store() {
    return this._store(`totals/${this.classroom_id}/${this.support_id}/${this.id}`);
  }

  static async restore(classroom_id, support_id, id) {
    return Model._restore(Total, `totals/${classroom_id}/${support_id}/${id}`);
  }

  static async load(classroom_id, id) {
    let path = classroom && classroom.length > 0 ? `classrooms/${classroom_id}/totals/${id}.json` : `totals/${id}.json`;
    return Model._load(Total, path);
  }

}