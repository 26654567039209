import utils from "./utils.js";

import Lesson from "../models/Lesson";
import Lessons from "../models/Lessons";

export default {
  state: {
    lessons: null,
    lesson: null
  },
  getters: {
    getLessons: (state) => () => {
      if (state.lessons) {
        return state.lessons;
      }
      return [];
    },
    getChapterLessons: (state) => (chapter) => {
      if (state.lessons && chapter) {
        return state.lessons.filter(lesson => lesson.chapter_id == chapter.id);
      }
      return [];
    },
    getModulLessons: (state) => (modul) => {
      if (state.lessons && modul) {
        return state.lessons.filter(lesson => lesson.modul_id == modul.id);
      }
      return [];
    },
    getFastTrackLessons: (state) => (toolkit) => {
      if (state.lessons && toolkit) {
        const lessons = state.lessons.filter(lesson => lesson.toolkit_id == toolkit && lesson.fast_track);
        return lessons.sort((a, b) => a.position - b.position);
      }
      return [];
    },
    // TODO: lets refactor - you can have a case where you're trying to get a lesson by id but it just returns what ever is in state.lesson
    getLesson: (state) => (id) => {
      if (id) {
        if (state.lesson?.id === id) {
          return state.lesson;
        }
        else {
          return state.lessons?.find(lesson => lesson?.id == id);
        }
      }
      return state.lesson;
    },
    getLessonId: (state) => () => {
      return state.lesson ? state.lesson.id : null;
    }
  },
  actions: {
    async loadLessons({
      commit, rootGetters
    }, params = {}) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 100);
        let toolkit = utils.try(params, "toolkit", rootGetters.getToolkitId());
        let sections = utils.try(params, "sections", false);
        if (refresh || (await Lessons.count(toolkit, sections, search, 0, offset + limit)) <= 1) {
          let deleted = utils.try(params, "deleted", false);
          let lessons = await Lessons.load(toolkit, sections, search, offset, limit, deleted);
          if (lessons && lessons.length > 0) {
            await lessons.store();
          }
        }
        let lessons = await Lessons.restore(toolkit, sections, search, 0, offset + limit);
        commit("setLessons", lessons);
        return Promise.resolve(lessons);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadLesson({
      commit, rootGetters
    }, params = {}) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let toolkit = utils.try(params, "toolkit", rootGetters.getToolkitId());
        let sections = utils.try(params, "sections", false);
        let lesson = refresh ? null : await Lesson.restore(toolkit, params.lesson, sections);
        if (lesson == null) {
          lesson = await Lesson.load(toolkit, params.lesson, sections);
          if (lesson) {
            await lesson.store();
          }
        }
        commit("setLesson", lesson);
        return Promise.resolve(lesson);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async firstLesson({
      commit, dispatch, state, rootGetters
    }, params = {}) {
      if (state.lessons == null || state.lessons.length == 0) {
        await dispatch("loadLessons", {
          toolkit: params.toolkit || rootGetters.getToolkitId(),
          chapter: params.chapter || rootGetters.getChapterId(),
          modul: params.modul || rootGetters.getModulId()
        });
      }
      if (state.lessons && state.lessons.length > 0) {
        let lesson = state.lessons[0];
        commit("setLesson", lesson);
        return Promise.resolve(lesson);
      }
      return Promise.resolve();
    },
    async lastLesson({
      commit, dispatch, state, rootGetters
    }, params = {}) {
      if (state.lessons == null || state.lessons.length == 0) {
        await dispatch("loadLessons", {
          toolkit: params.toolkit || rootGetters.getToolkitId(),
          chapter: params.chapter || rootGetters.getChapterId(),
          modul: params.modul || rootGetters.getModulId()
        });
      }
      if (state.lessons && state.lessons.length > 0) {
        let lesson = state.lessons[state.lessons.length - 1];
        commit("setLesson", lesson);
        return Promise.resolve(lesson);
      }
      return Promise.resolve();
    },
    async nextLesson({
      commit, dispatch, state
    }, params = {}) {
      let current = state.lesson;
      let index = current ?
        state.lessons.findIndex(_lesson => _lesson.id === current.id) :
        state.lessons.findIndex(_lesson => _lesson.id === params.lesson);
      if (index + 1 < state.lessons.length) {
        let lesson = state.lessons[index + 1];
        commit("setLesson", lesson);
      }
      else if (current.modul_id && current.modul_id.length > 0) {
        await dispatch("nextModul");
      }
      else {
        await dispatch("nextChapter");
      }
    },
    async previousLesson({
      commit, dispatch, state
    }, params) {
      if (state.lessons && state.lesson) {
        let current = state.lesson;
        let index = state.lessons.findIndex(_lesson => _lesson.id === current.id);
        if (index > 0) {
          let lesson = state.lessons[index - 1];
          commit("setLesson", lesson);
        }
        else if (current.modul_id && current.modul_id.length > 0) {
          await dispatch("previousModul");
        }
        else {
          await dispatch("previousChapter");
        }
      }
    },
    async clearLessons({ commit }, params = {}) {
      await Lessons.clear();
      commit("resetLessons");
      return Promise.resolve();
    }
  },
  mutations: {
    setLessons: (state, lessons) => {
      state.lessons = lessons;
    },
    setLesson: (state, lesson) => {
      state.lesson = lesson;
    },
    resetLessons: (state) => {
      state.lessons = null;
      state.lesson = null;
    },
    // completion
    setLessonCompletion: (state, { lessonId, completionId }) => {
      if (state.lesson?.id === lessonId) {
        state.lesson.completion_id = completionId;
      }
      const lesson = state.lessons?.find(lesson => lesson.id === lessonId);
      if (lesson) {
        lesson.completion_id = completionId;
      }
    },
    // rating
    setLessonRating: (state, { lessonId, rating }) => {
      if (state.lesson?.id === lessonId) {
        state.lesson.ratings[0] = rating;
      }
      let lesson = state.lessons?.find(lesson => lesson.id === lessonId);
      if (lesson) {
        lesson.ratings[0] = rating;
      }
    }
  }
};