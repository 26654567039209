import Model from "./Model";
import Chapters from "./Chapters";

export default class Toolkit extends Model {

  id = null;
  type = null;
  name = null;
  title = null;
  description = null;
  image = null;
  ordinal = null;
  position = null;
  chapters_count = 0;
  moduls_count = 0;
  lessons_count = 0;
  sections_count = 0;
  current_toolkit = null;
  teacher_toolkit = false;
  created_at = null;
  updated_at = null;
  chapters = new Chapters();

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
    if (data && data["chapters"]) {
      this.chapters = new Chapters(data["chapters"]);
    }
    else {
      this.chapters = new Chapters();
    }
  }

  async store() {
    this._store(`toolkits/${this.id}`);
    if (this.chapters && this.chapters.length > 0) {
      this.chapters.store();
    }
    return true;
  }

  static async restore(id, chapters = false, moduls = false, lessons = false, sections = false) {
    let toolkit = Model._restore(Toolkit, `toolkits/${id}`);
    if (toolkit && chapters) {
      toolkit.chapters = Chapters.restore(id, moduls, lessons, sections);
    }
    return toolkit;
  }

  static async load(id, chapters = false, moduls = false, lessons = false, sections = false) {
    let params = this.params({
      chapters: chapters,
      moduls: moduls,
      lessons: lessons,
      sections: sections
    });
    return Model._load(Toolkit, `toolkits/${id}.json?${params}`);
  }

}