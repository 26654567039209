import utils from "./utils.js";

import Totals from "@/models/Totals";

export default {
  state: {
    totals: null,
    schoolTallies: null
  },
  getters: {
    getWaypointTotals: (state) => (waypoint) => {
      if (state.totals && waypoint) {
        return state.totals.filter(total => total.totalable_type == "Waypoint" && total.totalable_id == waypoint.id);
      }
      return [];
    },
    getSchoolTallies: (state) => () => {
      return state.schoolTallies;
    }
  },
  actions: {
    async loadTotals({ commit }, params) {
      try {
        // let refresh = utils.try(params, "refresh", false);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 100);
        let classroom = utils.try(params, "classroom", null);
        let waypoint = utils.try(params, "waypoint", null);
        let school = utils.try(params, "school", null);
        // let totals = refresh ? [] : await Totals.restore(classroom, offset, offset + limit);
        // if (totals == null || totals.length == 0) {
        let totals = await Totals.load(classroom, waypoint, school, offset, limit);
        // NOTE: no need to store totals for now, we may want to again in the future though
        // if (totals && totals.length > 0) {
        //   await totals.store();
        // }
        // }
        // NOTE: restoring totals is currently not efficent and we refresh each time to get the latest data anyways
        // TODO: we should come back and try to optimize this though
        // totals = await Totals.restore(classroom, offset, offset + limit);
        if (school) {
          commit("setSchoolTallies", totals.find(t => t.school_id === school));
        }
        else {
          commit("setTotals", totals);
        }
        return Promise.resolve(totals);
      }
      catch (error) {
        return Promise.reject(error);
      }
    }
  },
  mutations: {
    setTotals: (state, totals) => {
      state.totals = totals;
    },
    setSchoolTallies: (state, tallies) => {
      state.schoolTallies = tallies;
    },
    resetTotals: (state) => {
      state.totals = null;
    }
  }
};