import Models from "./Models";
import Section from "./Section";

export default class Sections extends Models {

  constructor(models = []) {
    super(Section, models);
  }

  static async clear() {
    return Models._clear("sections");
  }

  static async count(toolkit, lesson, search = "", offset = 0, limit = 50) {
    let path = `sections/${toolkit}/${lesson}/`;
    return Models._count(path, search, offset, limit, "ordinal", "name,title,description");
  }

  static async restore(toolkit, lesson, search = "", offset = 0, limit = 50) {
    let path = `sections/${toolkit}/${lesson}/`;
    return Models._restore(Sections, Section, path, search, offset, limit, "ordinal", "name,title,description");
  }

  static async load(toolkit, lesson, locale, search = "", offset = 0, limit = 50, deleted = false) {
    let path = `toolkits/${toolkit}/lessons/${lesson}/sections.json`;
    if (locale) {
      path = `toolkits/${toolkit}/lessons/${lesson}/sections.json?content_locale=${locale}`;
    }
    return Models._load(Sections, Section, path, search, offset, limit, deleted);
  }

}