import utils from "./utils.js";

import Modul from "../models/Modul";
import Moduls from "../models/Moduls";

export default {
  state: {
    moduls: null,
    modul: null
  },
  getters: {
    getModuls: (state) => () => {
      if (state.moduls) {
        return state.moduls;
      }
      return [];
    },
    getChapterModuls: (state) => (chapter) => {
      if (state.moduls && chapter) {
        return state.moduls.filter(modul => modul.chapter_id == chapter.id);
      }
      return [];
    },
    getModul: (state) => () => {
      return state.modul;
    },
    getModulId: (state) => () => {
      return state.modul ? state.modul.id : null;
    }
  },
  actions: {
    async loadModuls({
      commit, rootGetters
    }, params = {}) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 100);
        let toolkit = utils.try(params, "toolkit", rootGetters.getToolkitId());
        let chapter = utils.try(params, "chapter", rootGetters.getChapterId());
        let lessons = utils.try(params, "lessons", false);
        let sections = utils.try(params, "sections", false);
        if (refresh || (await Moduls.count(toolkit, chapter, lessons, sections, search, 0, offset + limit)) == 0) {
          let deleted = utils.try(params, "deleted", false);
          let moduls = await Moduls.load(toolkit, chapter, lessons, sections, search, offset, limit, deleted);
          if (moduls && moduls.length > 0) {
            await moduls.store();
          }
        }
        let moduls = await Moduls.restore(toolkit, chapter, lessons, sections, search, 0, offset + limit);
        commit("setModuls", moduls);
        return Promise.resolve(moduls);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadModul({
      commit, rootGetters
    }, params = {}) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let toolkit = utils.try(params, "toolkit", rootGetters.getToolkitId());
        let chapter = utils.try(params, "chapter", rootGetters.getChapterId());
        let lessons = utils.try(params, "lessons", true);
        let sections = utils.try(params, "sections", false);
        let modul = refresh ? null : await Modul.restore(toolkit, chapter, params.modul);
        if (modul == null) {
          modul = await Modul.load(toolkit, chapter, params.modul, lessons, sections);
          if (modul) {
            await modul.store();
          }
        }
        commit("setModul", modul);
        return Promise.resolve(modul);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async firstModul({
      commit, dispatch, state, rootGetters
    }, params = {}) {
      if (state.moduls == null || state.moduls.length == 0) {
        await dispatch("loadModuls", {
          toolkit: params.toolkit || rootGetters.getToolkitId(),
          chapter: params.chapter || rootGetters.getChapterId()
        });
      }
      if (state.moduls && state.moduls.length > 0) {
        let modul = state.moduls[0];
        commit("setModul", modul);
        if (modul.lessons_count > 0) {
          await dispatch("loadLessons", {
            toolkit: modul.toolkit_id,
            chapter: modul.chapter_id,
            modul: modul.id
          });
          await dispatch("firstLesson");
        }
        return Promise.resolve(modul);
      }
      return Promise.resolve();
    },
    async lastModul({
      commit, dispatch, state, rootGetters
    }, params = {}) {
      if (state.moduls == null || state.moduls.length == 0) {
        await dispatch("loadModuls", {
          toolkit: params.toolkit || rootGetters.getToolkitId(),
          chapter: params.chapter || rootGetters.getChapterId()
        });
      }
      if (state.moduls && state.moduls.length > 0) {
        let modul = state.moduls[state.moduls.length - 1];
        commit("setModul", modul);
        if (modul.lessons_count > 0) {
          await dispatch("loadLessons", {
            toolkit: modul.toolkit_id,
            chapter: modul.chapter_id,
            modul: modul.id
          });
          await dispatch("lastLesson");
        }
        return Promise.resolve(modul);
      }
      return Promise.resolve();
    },
    async nextModul({
      commit, dispatch, state
    }, params = {}) {
      let current = state.modul;
      let index = state.moduls.findIndex(_modul => _modul.id === current.id);
      if (index + 1 < state.moduls.length) {
        let modul = state.moduls[index + 1];
        commit("setModul", modul);
        if (modul.lessons_count > 0) {
          await dispatch("loadLessons", {
            toolkit: modul.toolkit_id,
            chapter: modul.chapter_id,
            modul: modul.id
          });
          await dispatch("firstLesson");
        }
      }
      else {
        await dispatch("nextChapter");
      }
    },
    async previousModul({
      commit, dispatch, state
    }, params) {
      let current = state.modul;
      let index = state.moduls.findIndex(_modul => _modul.id === current.id);
      if (index > 0) {
        let modul = state.moduls[index - 1];
        commit("setModul", modul);
        if (modul.lessons_count > 0) {
          await dispatch("loadLessons", {
            toolkit: modul.toolkit_id,
            chapter: modul.chapter_id,
            modul: modul.id
          });
          await dispatch("lastLesson");
        }
      }
      else {
        await dispatch("previousChapter");
      }
    },
    async clearModuls({ commit }, params = {}) {
      await Moduls.clear();
      commit("resetModuls");
      return Promise.resolve();
    }
  },
  mutations: {
    setModuls: (state, moduls) => {
      state.moduls = moduls;
    },
    setModul: (state, modul) => {
      state.modul = modul;
    },
    resetModuls: (state) => {
      state.moduls = null;
      state.modul = null;
    }
  }
};