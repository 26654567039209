import utils from "./utils.js";

import Chapter from "../models/Chapter";
import Chapters from "../models/Chapters";

export default {
  state: {
    chapters: null,
    chapter: null
  },
  getters: {
    getChapters: (state) => () => {
      if (state.chapters) {
        return state.chapters;
      }
      return [];
    },
    getToolkitChapters: (state) => (toolkit) => {
      if (state.chapters && toolkit) {
        return state.chapters.filter(chapter => chapter.toolkit_id == toolkit.id);
      }
      return [];
    },
    getChapter: (state) => () => {
      return state.chapter;
    },
    getChapterId: (state) => () => {
      return state.chapter ? state.chapter.id : null;
    }
  },
  actions: {
    async loadChapters({
      commit, rootGetters
    }, params = {}) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 100);
        let toolkit = utils.try(params, "toolkit", rootGetters.getToolkitId());
        let moduls = utils.try(params, "moduls", false);
        let lessons = utils.try(params, "lessons", false);
        let sections = utils.try(params, "sections", false);
        if (refresh || (await Chapters.count(toolkit, moduls, lessons, sections, search, 0, offset + limit)) == 0) {
          let deleted = utils.try(params, "deleted", false);
          let chapters = await Chapters.load(toolkit, moduls, lessons, sections, search, offset, limit, deleted);
          if (chapters && chapters.length > 0) {
            await chapters.store();
          }
        }
        let chapters = await Chapters.restore(toolkit, moduls, lessons, sections, search, 0, offset + limit);
        commit("setChapters", chapters);
        return Promise.resolve(chapters);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadChapter({
      commit, rootGetters
    }, params = {}) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let toolkit = utils.try(params, "toolkit", rootGetters.getToolkitId());
        let moduls = utils.try(params, "moduls", false);
        let lessons = utils.try(params, "lessons", false);
        let sections = utils.try(params, "sections", false);
        let chapter = refresh ? null : await Chapter.restore(toolkit, params.chapter, moduls, lessons, sections);
        if (chapter == null) {
          chapter = await Chapter.load(toolkit, params.chapter, moduls, lessons, sections);
          if (chapter) {
            await chapter.store();
          }
        }
        commit("setChapter", chapter);
        return Promise.resolve(chapter);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async firstChapter({
      commit, dispatch, state, rootGetters
    }, params = {}) {
      if (state.chapters == null || state.chapters.length == 0) {
        await dispatch("loadChapters", {
          toolkit: params.toolkit || rootGetters.getToolkitId()
        });
      }
      if (state.chapters && state.chapters.length > 0) {
        let chapter = state.chapters[0];
        commit("setChapter", chapter);
        return Promise.resolve(chapter);
      }
      return Promise.resolve();
    },
    async nextChapter({
      commit, dispatch, state
    }, params = {}) {
      let current = state.chapter;
      if (state.chapters) {
        let index = state.chapters.findIndex(_chapter => _chapter.id === current.id);
        if (index + 1 < state.chapters.length) {
          let chapter = state.chapters[index + 1];
          commit("setChapter", chapter);
          if (chapter.moduls_count > 0) {
            await dispatch("loadModuls", {
              toolkit: chapter.toolkit_id,
              chapter: chapter.id
            });
            await dispatch("firstModul");
          }
          else if (chapter.lessons_count > 0) {
            await dispatch("loadLessons", {
              toolkit: chapter.toolkit_id,
              chapter: chapter.id
            });
            await dispatch("firstLesson");
          }
        }
      }
    },
    async previousChapter({
      commit, dispatch, state
    }, params = {}) {
      let current = state.chapter;
      if (state.chapters) {
        let index = state.chapters.findIndex(_chapter => _chapter.id === current.id);
        if (index > 0) {
          let chapter = state.chapters[index - 1];
          commit("setChapter", chapter);
          if (chapter.moduls_count > 0) {
            await dispatch("loadModuls", {
              toolkit: chapter.toolkit_id,
              chapter: chapter.id
            });
            await dispatch("lastModul");
          }
          else {
            await dispatch("loadLessons", {
              toolkit: chapter.toolkit_id,
              chapter: chapter.id
            });
            await dispatch("lastLesson");
          }
        }
      }
    },
    async clearChapters({ commit }, params = {}) {
      await Chapters.clear();
      commit("resetChapters");
      return Promise.resolve();
    }
  },
  mutations: {
    setChapters: (state, chapters) => {
      state.chapters = chapters;
    },
    setChapter: (state, chapter) => {
      state.chapter = chapter;
    },
    resetChapters: (state) => {
      state.chapters = null;
      state.chapter = null;
    }
  }
};