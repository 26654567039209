import Models from "./Models";
import Toolkit from "./Toolkit";

export default class Toolkits extends Models {

  constructor(models = []) {
    super(Toolkit, models);
  }

  static async clear() {
    return Models._clear("toolkits");
  }

  static async count(search = "", offset = 0, limit = 50) {
    return Models._count("toolkits", search, offset, limit, "ordinal", "name,title,description");
  }

  static async restore(search = "", offset = 0, limit = 50) {
    return Models._restore(Toolkits, Toolkit, "toolkits", search, offset, limit, "ordinal", "name,title,description");
  }

  static async load(search = "", offset = 0, limit = 50, deleted = false) {
    return Models._load(Toolkits, Toolkit, "/toolkits.json", search, offset, limit, deleted);
  }
}