import utils from "./utils.js";

import School from "@/models/School";
import Schools from "@/models/Schools";

export default {
  state: {
    schools: null,
    school: null
  },
  getters: {
    getSchools: (state) => (ids) => {
      if (state.schools && state.schools.length > 0 && ids && ids.length > 0) {
        return state.schools.filter(school => ids.includes(school.id));
      }
      return state.schools;
    },
    getSchool: (state) => (id) => {
      if (state.schools && state.schools.length > 0 && id) {
        return state.schools.find(school => school.id == id);
      }
      return state.school;
    }
  },
  actions: {
    async loadSchools({ commit }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 10);
        let teachers = utils.try(params, "teachers", false);
        let students = utils.try(params, "students", false);
        let classrooms = utils.try(params, "classrooms", false);
        if (refresh || (await Schools.count(search, 0, offset + limit)) == 0) {
          let deleted = utils.try(params, "deleted", false);
          let schools = await Schools.load(teachers, students, classrooms, search, offset, limit, deleted);
          if (schools && schools.length > 0) {
            await schools.store();
          }
        }
        let schools = await Schools.restore(search, 0, offset + limit);
        commit("setSchools", schools);
        return Promise.resolve(schools);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadSchool({ commit }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let school = refresh ? null : await School.restore(params.id);
        if (school == null) {
          let teachers = utils.try(params, "teachers", false);
          let students = utils.try(params, "students", false);
          let classrooms = utils.try(params, "classrooms", false);
          let licenses = utils.try(params, "licenses", false);
          school = await School.load(params.id, teachers, students, classrooms, licenses);
          if (school) {
            await school.store();
          }
        }
        commit("setSchool", school);
        return Promise.resolve(school);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async saveSchool({ commit }, params = {}) {
      try {
        let _school = new School();
        _school.id = utils.try(params, "id", null);
        _school.name = utils.try(params, "name", "");
        _school.title = utils.try(params, "title", "");
        _school.description = utils.try(params, "description", "");
        _school.country = utils.try(params, "country", "");
        _school.state = utils.try(params, "state", "");
        _school.city = utils.try(params, "city", "");
        _school.street = utils.try(params, "street", "");
        _school.zipcode = utils.try(params, "zipcode", "");
        let image_data = utils.try(params, "image_data", null);
        let remove_image = utils.try(params, "remove_image", null);
        let school = await _school.save(image_data, remove_image);
        await school.store();
        commit("setSchool", school);
        return Promise.resolve(school);
      }
      catch (error) {
        return Promise.reject(error);
      }
    }
  },
  mutations: {
    setSchools: (state, schools) => {
      state.schools = schools;
    },
    setSchool: (state, school) => {
      state.school = school;
      if (state.schools) {
        const index = state.schools.findIndex(_school => _school.id === school.id);
        if (index > -1) {
          state.schools.splice(index, 1, school);
        }
        else {
          state.schools.push(school);
        }
      }
    },
    resetSchools: (state) => {
      state.schools = null;
      state.school = null;
    }
  }
};