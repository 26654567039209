import utils from "./utils.js";
import Collection from "../models/Collection";
import Collections from "../models/Collections";

import Server from "@/services/Server";

export default {
  state: {
    archivedSharedCollections: [],
    collections: [],
    collection: null,
    reloadCollections: false
  },
  getters: {
    getArchivedSharedCollections: (state) => () => {
      if (state.archivedSharedCollections && state.archivedSharedCollections.length > 0) {
        return state.archivedSharedCollections;
      }
    },
    getCollections: (state) => () => {
      if (state.collections && state.collections.length > 0) {
        return state.collections.filter(collection => !collection.archived_shared_with_me);
      }
    },
    getCollection: (state) => () => {
      return state.collection;
    },
    getReloadCollections: (state) => () => {
      return state.reloadCollections;
    }
  },
  actions: {
    async loadCollections({ commit }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 48);
        let deleted = utils.try(params, "deleted", false);
        let grade_bands = utils.try(params, "grade_bands", null);
        let collection_topic_ids = utils.try(params, "collection_topic_ids", null);
        let my_collections = utils.try(params, "my_collections", false);
        let shared_with_me = utils.try(params, "shared_with_me", false);
        let archived_shared_with_me = utils.try(params, "archived_shared_with_me", false);

        let collections = refresh ? [] : await Collections.restore(search, 0, offset + limit);
        if (collections == null || collections.length == 0) {
          collections = await Collections.load(search, offset, limit, deleted, grade_bands, collection_topic_ids, my_collections, shared_with_me, archived_shared_with_me);
          if (collections && collections.length > 0) {
            await collections.store();
          }
        }

        offset > 0 ? commit("pushCollections", collections) : commit("setCollections", collections);

        return Promise.resolve(collections);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadArchivedSharedCollections({ commit }, params) {
      try {
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 48);
        let deleted = utils.try(params, "deleted", false);
        let grade_bands = utils.try(params, "grade_bands", null);
        let collection_topic_ids = utils.try(params, "collection_topic_ids", null);

        const archivedSharedCollections = await Collections.load(search, offset, limit, deleted, grade_bands, collection_topic_ids, false, true, true);

        offset > 0 ? commit("pushArchivedSharedCollections", archivedSharedCollections) : commit("setArchivedSharedCollections", archivedSharedCollections);

        return Promise.resolve(archivedSharedCollections);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadCollection({ commit }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let collection = refresh ? null : await Collection.restore(params.id);
        if (collection == null) {
          collection = await Collection.load(params.id);
          if (collection) {
            await collection.store();
          }
        }
        commit("setCollection", collection);
        return Promise.resolve(collection);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async saveCollection({ commit }, params = {}) {
      try {
        let _collection = new Collection();
        _collection.id = utils.try(params, "id", null);
        _collection.name = utils.try(params, "name", null);
        _collection.grade_band = utils.try(params, "grade_band", null);

        const response = await _collection.save();
        commit("setCollection", response);
        return Promise.resolve(response);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    // NOTE: this needs refactoring, doesn't follow patterns used elsewhere
    // would be nice to move away from the model pattern and use a more traditional vuex/server wrapper pattern
    // but not a fan of how its being done here
    async copyCollection(_args, params = {}) {
      const url = process.env.VUE_APP_API_URL + `/collections/${params.id}/copy.json`;
      const response = await Server.post(url, {
        id: params.id,
        name: params.name,
        grade_band: params.grade_band
      });
      return response;
    },
    async deleteCollection({ commit }, params = {}) {
      let collection = new Collection();
      collection.id = params.id;
      await collection.delete();
      commit("removeCollection", collection);
      return Promise.resolve(null);
    },
    async requestCollectionAccess(_args, params = {}) {
      const url = process.env.VUE_APP_API_URL + "/collections/create-collection-share-request.json";
      const response = await Server.post(url, {
        id: params.id,
        message: params.message
      });
      return response;
    },
    async acceptCollectionRequest(_args, params = {}) {
      const url = process.env.VUE_APP_API_URL + "/collections/accept-collection-share-request.json";
      const response = await Server.post(url, {
        collection_request_id: params.id,
        collection_access_type: params.access_type
      });
      return response;
    },
    async loadCollectionTopics() {
      const url = process.env.VUE_APP_API_URL + "/collection_topics.json";
      const response = await Server.get(url);
      return response;
    },
    async loadAvailableItems(_args, params = {}) {
      let url = process.env.VUE_APP_API_URL + "/collections/available-items.json";
      let search = utils.try(params, "search", null);
      url += search ? `?search=${search}` : "";
      const response = await Server.get(url);
      return response;
    },
    async addCollectionItems(_args, params = {}) {
      const url = process.env.VUE_APP_API_URL + "/collections/add-collection-items.json";
      const response = await Server.post(url, {
        id: params.id,
        collection_items: params.collection_items
      });
      return response;
    },
    async addItemToCollections(_args, params = {}) {
      const url = process.env.VUE_APP_API_URL + "/collections/add-collection-item.json";
      let collections = utils.try(params, "collections", []);
      let lesson_id = utils.try(params, "lesson_id", null);
      let resource_id = utils.try(params, "resource_id", null);

      for (const collection of collections) {
        let params = {
          collection_id: collection
        };
        if (lesson_id) {
          params["lesson_id"] = lesson_id;
        }
        else if (resource_id) {
          params["resource_id"] = resource_id;
        }
        await Server.post(url, params);
      }
      return Promise.resolve(null);
    },
    async updateCollectionItems(_args, params = {}) {
      const url = process.env.VUE_APP_API_URL + "/collections/update-collection-items.json";
      const response = await Server.post(url, {
        id: params.collection_id,
        collection_items: params.collection_items
      });
      return response;
    },
    async deleteCollectionItem(_args, params = {}) {
      const url = process.env.VUE_APP_API_URL + "/collections/remove-collection-item.json";
      const response = await Server.post(url, params);
      return response;
    },
    async shareCollection(_args, params = {}) {
      const url = process.env.VUE_APP_API_URL + "/collections/create-collection-share.json";
      const response = await Server.post(url, params);
      return response;
    },
    async archiveShare(_args, params = {}) {
      const url = process.env.VUE_APP_API_URL + "/collections/archive-share.json";
      const response = await Server.post(url, params);
      return response;
    },
    async removeShare(_args, params = {}) {
      const url = process.env.VUE_APP_API_URL + "/collections/remove-share.json";
      const response = await Server.post(url, params);
      return response;
    },
    async restoreShare(_args, params = {}) {
      const url = process.env.VUE_APP_API_URL + "/collections/restore-share.json";
      const response = await Server.post(url, params);
      return response;
    }
  },
  mutations: {
    setCollections: (state, collections) => {
      state.collections = collections;
    },
    setCollection: (state, collection) => {
      const index = state.collections.findIndex(_collection => _collection.id === collection.id);
      if (index > -1) {
        state.collections.splice(index, 1, collection);
      }
      else {
        state.collections.push(collection);
      }
      state.collection = collection;
    },
    setArchivedSharedCollections: (state, collections) => {
      state.archivedSharedCollections = collections;
    },
    removeCollection(state, collection) {
      if (state.collections) {
        const index = state.collections.findIndex(_collection => _collection.id === collection.id);
        if (index > -1) {
          state.collections.splice(index, 1);
        }
      }
    },
    pushCollections: (state, collections) => {
      state["collections"] = [
        ...state["collections"], ...collections
      ];
    },
    resetCollections: (state) => {
      state.collections = [];
      state.collection = null;
    },
    pushArchivedSharedCollections: (state, collections) => {
      state["archivedSharedCollections"] = [
        ...state["archivedSharedCollections"], ...collections
      ];
    },
    resetArchivedSharedCollections: (state) => {
      state.archivedSharedCollections = [];
    },
    setReloadCollections: (state, reload) => {
      state.reloadCollections = reload;
    },
    setCollectionItemCompletion: (state, { lessonId, completionId }) => {
      const item = state.collection?.items.find(item => item.id === lessonId);
      if (item) {
        item.completion_id = completionId;
      }
    }
  }
};
