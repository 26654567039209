import utils from "./utils.js";

import Section from "../models/Section";
import Sections from "../models/Sections";

export default {
  state: {
    sections: null,
    section: null
  },
  getters: {
    getSections: (state) => (lesson) => {
      if (state.sections && lesson) {
        return state.sections.filter(section => section.lesson_id == lesson.id);
      }
      if (state.sections) {
        return state.sections;
      }
      if (state.lesson && state.lesson.sections) {
        return state.lesson.sections;
      }
      return null;
    },
    getSection: (state) => () => {
      return state.section;
    },
    getSectionId: (state) => () => {
      return state.section ? state.section.id : null;
    }
  },
  actions: {
    async loadSections({
      commit, rootGetters
    }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 100);
        let toolkit = utils.try(params, "toolkit", rootGetters.getToolkitId());
        let lesson = utils.try(params, "lesson", rootGetters.getLessonId());
        let locale = utils.try(params, "locale", null);
        if (refresh || (await Sections.count(toolkit, lesson, search, 0, offset + limit)) == 0) {
          let deleted = utils.try(params, "deleted", false);
          let sections = await Sections.load(toolkit, lesson, locale, search, offset, limit, deleted);
          if (sections && sections.length > 0) {
            await sections.store();
          }
        }
        let sections = await Sections.restore(toolkit, lesson, search, 0, offset + limit);
        commit("setSections", sections);
        return Promise.resolve(sections);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadSection({
      commit, rootGetters
    }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let toolkit = utils.try(params, "toolkit", rootGetters.getToolkitId());
        let lesson = utils.try(params, "lesson", rootGetters.getLessonId());
        let section = refresh ? null : await Section.restore(toolkit, lesson, params.section);
        if (section == null) {
          section = await Section.load(toolkit, lesson, params.section, true);
          if (section) {
            await section.store();
          }
        }
        commit("setSection", section);
        return Promise.resolve(section);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async clearSections({ commit }, params = {}) {
      await Sections.clear();
      commit("resetSections");
      return Promise.resolve();
    }
  },
  mutations: {
    setSections: (state, sections) => {
      state.sections = sections;
    },
    setSection: (state, section) => {
      state.section = section;
    },
    resetSections: (state) => {
      state.sections = null;
      state.section = null;
    }
  }
};