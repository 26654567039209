import utils from "./utils.js";

import Completion from "@/models/Completion";
import Completions from "@/models/Completions";

export default {
  state: {
    completions: null,
    completionsList: [],
    completionsPagination: {
      requestHeaders: {
        page: 1,
        per_page: 5
      },
      responseHeaders: {}
    }
  },
  getters: {
    getCompletions: (state) => () => {
      return state.completions;
    },
    completionsList(state) {
      return state.completionsList;
    },
    completionsPagination(state) {
      return state.completionsPagination;
    },
    getCompletion: (state) => (id) => {
      return state.completions ? state.completions.find(c => c.id == id) : null;
    },
    getLessonCompletion: (state) => (id, term_id) => {
      if (!term_id) {
        return state.completions ? state.completions.find(completion => completion.lesson_id === id) : null;
      }
      else {
        return state.completions ? state.completions.find(completion => completion.lesson_id === id && completion.term_id === term_id) : null;
      }
    },
    getUserCompletions: (state) => (user) => {
      if (state.completions && user) {
        return state.completions.filter(completion => completion.user_id == user.id);
      }
      return [];
    },
    getToolkitCompletions: (state) => (user, toolkit) => {
      if (state.completions && user && toolkit) {
        const id = toolkit?.id || toolkit;
        return state.completions.filter(completion => completion.user_id == user.id && completion.toolkit_id == id);
      }
      return [];
    }
  },
  actions: {
    async loadCompletions({ commit }, params) {
      try {
        let temp = utils.try(params, "temp", 0);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 100);
        let user = utils.try(params, "user", null);
        let classroom = utils.try(params, "classroom", null);
        let toolkit = utils.try(params, "toolkit", null);
        let chapter = utils.try(params, "chapter", null);
        let lesson = utils.try(params, "lesson", null);
        /*
        Temporarily removing all lines dealing with storing completions, as storeByClassroom was
        not working effectively, and is a different pattern than used elsewhere in the codebase. More
        testing should be done on that, and classrooms should be considered part of the default fields in storing
        (aka all storage should be "store by classroom")
        if (refresh || (await Completions.count(user, toolkit, chapter, lesson, offset, limit)) == 0) {
          */
        let completions = await Completions.load(user, classroom, toolkit, chapter, lesson, offset, limit, false, false);
        /*
          if (completions && completions.length > 0) {
            if (classroom) {
              await completions.storeByClassroom(classroom);
            }
            else {
              await completions.store();
            }
          }
        // let completions = await Completions.restore(user, classroom, toolkit, chapter, lesson, 0, offset + limit);
        */
        if (!temp) {
          commit("setCompletions", completions);
        }
        return Promise.resolve(completions);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async saveCompletion({ commit }, params = {}) {
      try {
        let _completion = new Completion();
        _completion.lesson_id = utils.try(params, "lesson", null);
        _completion.term_id = utils.try(params, "term", null);
        let completion = await _completion.save();
        await completion.store();
        commit("setCompletion", completion);
        commit("setLessonCompletion", {
          lessonId: params.lesson,
          completionId: completion.id
        }, { root: true });
        commit("setCollectionItemCompletion", {
          lessonId: params.lesson,
          completionId: completion.id
        });
        return Promise.resolve(completion);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteCompletion({ commit }, { lessonId, completionId }) {
      try {
        let _completion = new Completion();
        _completion.id = completionId;
        let completion = await _completion.delete();
        commit("setCompletion", { completionId });
        commit("setLessonCompletion", {
          lessonId,
          completionId: null
        }, { root: true });
        commit("setCollectionItemCompletion", {
          lessonId: lessonId,
          completionId: null
        });
        return Promise.resolve(completion);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async reviewCompletion({ _commit }, {
      lesson, id
    }) {
      try {
        let _completion = new Completion();
        _completion.id = id;
        _completion.lesson_id = lesson;
        const completed = await _completion.markReviewed();
        return Promise.resolve(completed);
      }
      catch (error) {
        return Promise.resolve(error);
      }
    },
    // new - paginated
    async loadCompletionsPaginated({ commit }, params) {
      let _completions = new Completions();
      const response = await _completions.loadCompletions(params);
      commit("setCompletionsList", response.data);
      commit("setCompletionsReponseHeaders", response.pagination);
    }
  },
  mutations: {
    setCompletions: (state, completions) => {
      state.completions = completions;
    },
    setCompletionsList: (state, completions) => {
      state.completionsList = completions;
    },
    setCompletionsReponseHeaders: (state, headers) => {
      state.completionsPagination.responseHeaders = headers;
    },
    setCompletionsRequestHeaders: (state, headers) => {
      state.completionsPagination.requestHeaders = headers;
    },
    setCompletion: (state, completion) => {
      if (state.completions) {
        const id = completion.id || completion.completionId;
        const index = state.completions.findIndex(c => c.id === id);
        if (index > -1) {
          state.completions.splice(index, 1, completion);
        }
        else {
          state.completions.push(completion);
        }
      }
    },
    resetCompletions: (state) => {
      state.completions = null;
    }
  }
};