import Model from "./Model";
import Lessons from "./Lessons";

export default class Modul extends Model {

  id = null;
  type = null;
  name = null;
  title = null;
  description = null;
  toolkit_id = null;
  chapter_id = null;
  ordinal = null;
  position = null;
  lessons_count = 0;
  sections_count = 0;
  created_at = null;
  updated_at = null;
  lessons = new Lessons();

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
    if (data && data["lessons"]) {
      this.lessons = new Lessons(data["lessons"]);
    }
    else {
      this.lessons = new Lessons();
    }
  }

  async store() {
    this._store(`moduls/${this.toolkit_id}/${this.chapter_id}/${this.id}`);
    if (this.lessons && this.lessons.length > 0) {
      this.lessons.store();
    }
    return true;
  }

  static async restore(toolkit, chapter, id, lessons = false, sections = false) {
    let modul = Model._restore(Modul, `moduls/${toolkit}/${chapter}/${id}`);
    if (modul && lessons) {
      modul.lessons = Lessons.restore(toolkit, chapter, id, sections);
    }
    return modul;
  }

  static async load(toolkit, chapter, id, lessons = false, sections = false) {
    return Model._load(Modul, `toolkits/${toolkit}/chapters/${chapter}/moduls/${id}.json?lessons=${lessons}&sections=${sections}`);
  }

}