import Model from "./Model";

export default class Collection extends Model {

  id = null;
  name = null;
  grade_band = null;
  icon = null;
  ordinal = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async store() {
    return this._store(`collections/${this.id}`);
  }

  static async restore(id) {
    return Model._restore(Collection, `collections/${id}.json`);
  }

  static async load(id) {
    let collection = Model._load(Collection, `collections/${id}.json`);
    return collection;
  }

  async save() {
    let path = this.id && this.id.length > 0 ? `collections/${this.id}.json` : "collections.json";
    return this._save(Collection, path, {
      name: this.name,
      grade_band: this.grade_band
    });
  }

  async delete() {
    await this._delete(`collections/${this.id}.json`);
    await this._remove(`collections/${this.id}`);
    return null;
  }

}
