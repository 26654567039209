import Model from "./Model";
import Moduls from "./Moduls";
import Lessons from "./Lessons";

export default class Chapter extends Model {

  id = null;
  type = null;
  name = null;
  title = null;
  description = null;
  toolkit_id = null;
  ordinal = null;
  position = null;
  moduls_count = 0;
  lessons_count = 0;
  sections_count = 0;
  created_at = null;
  updated_at = null;
  moduls = new Moduls();
  lessons = new Lessons();

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
    if (data && data["moduls"]) {
      this.moduls = new Moduls(data["moduls"]);
    }
    else {
      this.moduls = new Moduls();
    }
    if (data && data["lessons"]) {
      this.lessons = new Lessons(data["lessons"]);
    }
    else {
      this.lessons = new Lessons();
    }
  }

  async store() {
    this._store(`chapters/${this.toolkit_id}/${this.id}`);
    if (this.moduls && this.moduls.length > 0) {
      this.moduls.store();
    }
    if (this.lessons && this.lessons.length > 0) {
      this.lessons.store();
    }
  }

  static async restore(toolkit, id, moduls = false, lessons = false, sections = false) {
    let chapter = Model._restore(Chapter, `chapters/${toolkit}/${id}`);
    if (chapter && moduls) {
      chapter.moduls = Moduls.restore(toolkit, id, lessons, sections);
    }
    if (chapter && lessons) {
      chapter.lessons = Lessons.restore(toolkit, id, sections);
    }
    return chapter;
  }

  static async load(toolkit, id, moduls = false, lessons = false, sections = false) {
    return Model._load(Chapter, `toolkits/${toolkit}/chapters/${id}.json?moduls=${moduls}&lessons=${lessons}&sections=${sections}`);
  }

}