import utils from "./utils";

import Toolkit from "@/models/Toolkit";
import Toolkits from "@/models/Toolkits";

const wayfinderAcademy = "1d3f9e6c-4ca5-4600-95d9-f0427fd42a08";

export default {
  state: {
    toolkits: null,
    toolkit: null
  },
  getters: {
    getToolkits: (state) => () => {
      return state.toolkits?.filter(toolkit => toolkit?.hidden_from_frontend !== true);
    },
    getToolkit: (state) => (toolkit_id = null) => {
      if (state.toolkits?.length > 0 && toolkit_id) {
        return state.toolkits.filter(toolkit => toolkit.id == toolkit_id);
      }
      return state.toolkit;
    },
    getToolkitId: (state) => () => {
      return state.toolkit != null ? state.toolkit.id : null;
    },
    getCurrentToolkits: (state) => (user) => {
      if (user?.type === "Student") {
        return state.toolkits?.filter(toolkit => toolkit?.current_toolkit && toolkit?.hidden_from_frontend !== true && toolkit?.student_app_enabled === true);
      }
      else {
        return state.toolkits?.filter(t => t?.current_toolkit && t?.hidden_from_frontend !== true).filter(t => !t.teacher_toolkit);
      }
    },
    getUnassociatedToolkits: (state) => () => {
      return state.toolkits?.filter(t => t?.current_toolkit === false && t?.hidden_from_frontend !== true && !t.teacher_toolkit);
    },
    wayfinderAcademy(state) {
      return state.toolkit?.id === wayfinderAcademy ? state.toolkit : state.toolkits?.find(t => t.id === wayfinderAcademy);
    }
  },
  actions: {
    async loadToolkits({ commit }, params = {}) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 50);
        const count = await Toolkits.count(search, 0, offset + limit);
        if (refresh || count <= 1) {
          let deleted = utils.try(params, "deleted", false);
          let toolkits = await Toolkits.load(search, offset, limit, deleted);
          if (toolkits?.length > 0) {
            await toolkits.store();
          }
          commit("setToolkits", toolkits);
          return Promise.resolve(toolkits);
        }
        let toolkits = await Toolkits.restore(search, 0, offset + limit);
        commit("setToolkits", toolkits);
        return Promise.resolve(toolkits);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadToolkit({ commit }, params = {}) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let chapters = utils.try(params, "chapters", false);
        let moduls = utils.try(params, "moduls", false);
        let lessons = utils.try(params, "lessons", false);
        let sections = utils.try(params, "sections", false);
        let toolkit = refresh ? null : await Toolkit.restore(params.toolkit, chapters, moduls, lessons, sections);
        if (toolkit == null) {
          toolkit = await Toolkit.load(params.toolkit, chapters, moduls, lessons, sections);
          if (toolkit) {
            await toolkit.store();
          }
        }
        commit("setToolkit", toolkit);
        return Promise.resolve(toolkit);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadWayfinderAcademyToolkit({ dispatch }) {
      await dispatch("loadToolkit", {
        toolkit: wayfinderAcademy
      });
    },
    async clearToolkits({ commit }) {
      await Toolkits.clear();
      commit("resetToolkits");
      return Promise.resolve();
    }
  },
  mutations: {
    setToolkits: (state, toolkits) => {
      state.toolkits = toolkits;
    },
    setToolkit: (state, toolkit) => {
      state.toolkit = toolkit;
    },
    resetToolkits: (state) => {
      state.toolkits = null;
      state.toolkit = null;
    }
  }
};