import Models from "./Models";
import Total from "./Total";

export default class Totals extends Models {

  constructor(models = []) {
    super(Total, models);
  }

  static async clear() {
    return Models._clear("totals");
  }

  static async count(classroom = null, offset = 0, limit = 100) {
    let path = classroom && classroom.length > 0 ? `totals/${classroom}` : "totals";
    return Models._count(path, null, offset, limit);
  }

  static async restore(classroom = null, offset = 0, limit = 100) {
    let path = classroom && classroom.length > 0 ? `totals/${classroom}` : "totals";
    let totals = await Models._restore(Totals, Total, path, null, offset, limit);
    return totals;
  }

  static async load(classroom = null, waypoint = null, school = null, offset = 0, limit = 100) {
    let path = "totals.json";
    if (classroom?.length > 0) {
      path = `classrooms/${classroom}/totals.json`;
    }
    if (waypoint?.length > 0) {
      path = `waypoints/${waypoint}/totals.json` + (classroom ? `?classroom_id=${classroom}` : "");
      // path = `waypoints/${waypoint}/totals.json`;
    }
    if (school?.length > 0) {
      path = `waypoints/${waypoint}/school_tallies.json`;
    }
    return Models._load(Totals, Total, path, null, offset, limit);
  }

}
