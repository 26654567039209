import Models from "./Models";
import Lesson from "./Lesson";

export default class Lessons extends Models {

  constructor(models = []) {
    super(Lesson, models);
  }

  static async clear() {
    return Models._clear("lessons");
  }

  static async count(toolkit, sections = false, search = "", offset = 0, limit = 10) {
    // TODO utilize sections flags
    let path = `lessons/${toolkit}/`;
    return Models._count(path, search, offset, limit, "ordinal", "name,title,description");
  }

  static async restore(toolkit, sections = false, search = "", offset = 0, limit = 10) {
    // TODO utilize sections flags
    let path = `lessons/${toolkit}/`;
    return Models._restore(Lessons, Lesson, path, search, offset, limit, "ordinal", "name,title,description");
  }

  static async load(toolkit, sections = false, search = "", offset = 0, limit = 10, deleted = false) {
    let path = `toolkits/${toolkit}/lessons.json?sections=${sections}`;
    return Models._load(Lessons, Lesson, path, search, offset, limit, deleted);
  }

}